import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
	AppBar,
	Box,
	Button,
	Container,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import hipBabyLogoHorz from "./images/hipbaby_logo_horizontal_clear.png";
import hipBabyLogoVert from "./images/hipbaby_logo_vertical_clear.png";
import alyssa from "./images/alyssa.jpg";
import pantsNewGrey from "./images/pants_new_grey.jpg";
import pantsOldPink from "./images/pants_old_pink.jpg";
import childrensHospital from "./images/childrens-hospital.jpg";
import romper from "./images/purple_butterflies_romper.jpg";

const colors = {
	text: "#000",
	appBarBackground: "#000052",
	appBarText: "#fff",
	background1: "#eee",
	background2: "#bbb",
};

const App = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("sm"));

	return (
			<div className="App">
				<AppBar
					position="fixed"
					sx={{ backgroundColor: colors.appBarBackground }}
				>
					<Typography
						variant="h5"
						component="div"
						sx={{ flexGrow: 1 }}
					>
						HipBabyCo
					</Typography>
					<Box>
                        <a
                            href="https://www.etsy.com/shop/HipBabyCo"
                            target="_blank"
                            rel="noreferrer"
                            style={{
                                textDecoration: 'none'
                            }}
                        >
						<Button
							sx={{ color: colors.appBarText }}
						>
								Shop Now{" "}
						</Button>
							</a>
					</Box>
				</AppBar>
				<Box
					name="section0"
					sx={{
						backgroundImage: `linear-gradient(${colors.background1}, ${colors.background2})`,
						color: colors.text,
						height: "100%",
						width: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-around",
						alignItems: "center",
					}}
				>
					<Container maxWidth="md">
						<img
							src={matches ? hipBabyLogoHorz : hipBabyLogoVert}
							alt="HipBabyCo Logo"
							style={{ width: "100%" }}
						/>
						<Typography variant="h6" sx={{ marginTop: 4 }}>
							Specialty made clothing for babies that have hip
							dysplasia. Veteran and military spouse owned
							business with all United States made clothing.
						</Typography>
						<Typography sx={{ marginTop: 5 }}>
							Scroll down for more infomation about our business
							or click "Shop Now" at the top to go to our store!
						</Typography>
					</Container>
				</Box>

				<Box
					name="section1"
					sx={{
						backgroundImage: `linear-gradient(${colors.background2}, ${colors.background1})`,
						color: colors.text,
						paddingTop: 10,
						paddingBottom: 10,
						width: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-around",
						alignItems: "center",
					}}
				>
					<Container maxWidth="md">
						<Typography variant="h5" sx={{ marginBottom: 2 }}>
							About Hip Baby Co.
						</Typography>
						<img
							src={alyssa}
							alt="Alyssa"
							style={{ maxWidth: "100%", maxHeight: 300 }}
						/>

						<Typography variant="body1" sx={{ marginTop: 2 }}>
							When our child was diagnosed with hip
							dysplasia, we realized that there were no clothes to
							fit her in her harness. After making some of our
							own, we realized that other families could benefit
							from these clothes too. We started this business to help other kiddos with dysplasia. 
							Our baby clothes are all made here in the United States!
						</Typography>
					</Container>
				</Box>
				<Box
					name="section2"
					sx={{
						backgroundImage: `linear-gradient(${colors.background1}, ${colors.background2})`,
						color: colors.text,
						paddingTop: 10,
						paddingBottom: 10,
						width: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-around",
						alignItems: "center",
					}}
				>
					<Container maxWidth="md">
						<Typography variant="h5" sx={{ marginBottom: 2 }}>
							Pants From Scratch
						</Typography>

						<img
							src={pantsOldPink}
							alt="Pants Old Pink"
							style={{ maxWidth: "100%", maxHeight: 300 }}
						/>

						<Typography variant="body1" sx={{ marginTop: 2 }}>
							Our first pants were made entirely from scratch.
							Specifically made for hip dysplasia babies in a
							pavlik harness or rhino brace, in sizes from zero to 24
							months, our pants keep little legs warm all year
							long. Now, we've expanded to get our main line of pants
							manufactured in Colorado while still making custom
							orders by hand!
						</Typography>
					</Container>
				</Box>
				<Box
					name="section3"
					sx={{
						backgroundImage: `linear-gradient(${colors.background2}, ${colors.background1})`,
						color: colors.text,
						paddingTop: 10,
						paddingBottom: 10,
						width: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-around",
						alignItems: "center",
					}}
				>
					<Container maxWidth="md">
						<Typography variant="h5" sx={{ marginBottom: 2 }}>
							Made in the USA
						</Typography>
						<img
							src={pantsNewGrey}
							alt="Pants New Grey"
							style={{ maxWidth: "100%", maxHeight: 300 }}
						/>
						<Typography variant="body1" sx={{ marginTop: 2 }}>
							Instead of getting clothes made overseas, our
							dysplasia pants are manufactured right here in the United
							States Colorado, to be specific. This helps us keep
							a better connection to our community and support
							workers in our area. We even donate pants to various
							children's hospitals!
						</Typography>
					</Container>
				</Box>

				<Box
					name="section4"
					sx={{
						backgroundImage: `linear-gradient(${colors.background1}, ${colors.background2})`,
						color: colors.text,
						paddingTop: 10,
						paddingBottom: 10,
						width: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-around",
						alignItems: "center",
					}}
				>
					<Container maxWidth="md">
						<Typography variant="h5" sx={{ marginBottom: 2 }}>
							Donations
						</Typography>
						<img
							src={childrensHospital}
							alt="Children's Hospital"
							style={{ maxWidth: "100%", maxHeight: 300 }}
						/>
						<Typography variant="body1" sx={{ marginTop: 2 }}>
							For every 10 pairs of pants sold, we donate 1 pair
							to children's hospitals. When our daughter was first diagnosed the local children's hospital sent us home with a few things that might fit with her harness. That made sure she had something to wear while we figured everything out, which was a huge help in a difficult time. Now Hip Baby Co is giving back to our community to help other parents keep their little ones warm through their hip dysplasia journey. 
						</Typography>
					</Container>
				</Box>
				<Box
					name="section5"
					sx={{
						backgroundImage: `linear-gradient(${colors.background2}, ${colors.background1})`,
						color: colors.text,
						paddingTop: 10,
						paddingBottom: 10,
						width: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-around",
						alignItems: "center",
					}}
				>
					<Container maxWidth="md">
						<Typography variant="h5" sx={{ marginBottom: 2 }}>
							Rompers and Spica Casts
						</Typography>
						<img
							src={romper}
							alt="Pants New Grey"
							style={{ maxWidth: "100%", maxHeight: 300 }}
						/>
						<Typography variant="body1" sx={{ marginTop: 2 }}>
							Looking for something more than just a pair of
							pants? We offer rompers by custom order too. We also
							have done custom orders for pants to fit spica casts. If you don't see what you need listed on our site, send us a message and we'll be happy to help you out. When you're
							ready, click the "Shop Now" button at the top of the
							screen to go to our store!
						</Typography>
					</Container>
				</Box>
				<Box
					sx={{
						backgroundImage: `linear-gradient(${colors.background1}, ${colors.background2})`,
						color: colors.text,
						height: 100,
						width: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Typography>A Weinhaus Creations' business.</Typography>
					<Typography>
						Website Design by Scott Hubert @ Weinhaus Creations
					</Typography>
				</Box>
			</div>
	);
};

export default App;
